import { data, LinksFunction, MetaFunction } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from "@remix-run/react";
import styles from "./tailwind.css?url";
import toastStyles from "react-toastify/dist/ReactToastify.css?url";
import { ToastContainer } from "react-toastify";
import { withSentry } from "@sentry/remix";
import PublicEnv from "~/components/public-env";
import ErrorMessage from "~/components/errors/ErrorMessage";
import nestableStyles from "react-nestable/src/styles/index.css?url";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { clientEnvironment } from "~/environment.server";
import { config } from "@fortawesome/fontawesome-svg-core";

config.autoAddCss = false; /* eslint-disable import/first */

export function loader() {
  return data(
    {
      publicKeys: clientEnvironment(),
    },
    {
      // environment variables won't change often, so we can cache them for a minute
      headers: { "Cache-Control": "private, max-age=60" },
    }
  );
}

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: styles },
  { rel: "stylesheet", href: toastStyles },
  { rel: "stylesheet", href: nestableStyles },
];

export const meta: MetaFunction = () => [
  {
    title: "Eventur",
  },
  { charSet: "utf-8" },
  { viewport: "width=device-width, initial-scale=1" },
];

export function ErrorBoundary() {
  return <ErrorMessage />;
}

function App() {
  const data = useLoaderData<typeof loader>();

  return (
    <html
      lang="en"
      className="h-full font-helvetica text-sm text-[#333333] scrollbar-track-transparent scrollbar-thumb-eventur-blue-900"
    >
      <head>
        <Meta />
        <Links />
        <title>Eventur</title>
      </head>
      <body>
        <PublicEnv {...data.publicKeys} />
        <ToastContainer style={{ width: "fit-content" }} />
        <Outlet />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default withSentry(App, {
  wrapWithErrorBoundary: true,
});
